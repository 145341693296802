import { render, staticRenderFns } from "./game-help-category-edit.vue?vue&type=template&id=105f420f&scoped=true"
import script from "./game-help-category-edit.vue?vue&type=script&lang=ts"
export * from "./game-help-category-edit.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "105f420f",
  null
  
)

export default component.exports